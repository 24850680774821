@media (min-width: 1025px) {
    .kt-aside__brand {
        background-color: #1a1a27;
        box-shadow: none;
    }

    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg g [fill] {
        transition: fill 0.3s ease;
        fill: #494b74;
    }

    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg:hover g [fill] {
        transition: fill 0.3s ease;
    }

    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg g [fill] {
        transition: fill 0.3s ease;
        fill: #5d78ff;
    }

    .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg:hover g [fill] {
        transition: fill 0.3s ease;
    }
}

@media (max-width: 1024px) {
    .kt-header-mobile {
        background-color: #1a1a27;
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    }

    .kt-header__topbar--mobile-on .kt-header-mobile {
        box-shadow: none;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span {
        background: #484f66;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after {
        background: #484f66;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span {
        background: #ffffff;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::after {
        background: #ffffff;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span {
        background: #ffffff;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::after {
        background: #ffffff;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler i {
        color: #484f66;
    }

    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active i, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i {
        color: #ffffff;
    }
}

.kt-sc-2 {
    display: flex;
    flex-direction: column;
    position: relative;
}

.kt-sc-2 .kt-sc__bg {
    position: absolute;
    top: 4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 0;
}

.kt-sc-2 .kt-sc__bg .kt-svg-icon {
    height: 100%;
    width: auto;
}

.kt-sc-2 .kt-sc__bg--layer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.kt-sc-2 .kt-sc__bg--layer .kt-svg-icon {
    height: auto;
    width: 100%;
    fill: #f7f8fa;
}

.kt-sc-2 .kt-sc-bg-2 .kt-svg-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
}

.kt-sc-2 .kt-sc__top {
    min-height: 260px;
    position: relative;
    z-index: 1;
}

.kt-sc-2 .kt-sc__top .kt-sc__content {
    margin-right: 50%;
    display: flex;
    flex-direction: column;
    min-height: 260px;
    padding: 1rem 3rem;
}

.kt-sc-2 .kt-sc__top .kt-sc__content .kt-sc__title {
    color: #48465b;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
}

.kt-sc-2 .kt-sc__top .kt-sc__content .kt-sc__form .input-group {
    width: 450px;
    box-shadow: 0 0 27px 0 rgba(160, 160, 191, 0.2);
}

.kt-sc-2 .kt-sc__top .kt-sc__content .kt-sc__form .input-group .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    padding: 0.65rem 2rem;
    background-color: #ffffff;
}

.kt-sc-2 .kt-sc__top .kt-sc__content .kt-sc__form .input-group .form-control {
    border: none;
    padding: 2.5rem 0;
    font-weight: 400;
    font-size: 1.1rem;
}

.kt-sc-2 .kt-sc__bottom {
    padding: 25px 25px 0;
    position: relative;
    z-index: 1;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs {
    margin-bottom: -25px;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs.nav-tabs-line {
    border-bottom: 0;
    padding: 0 3rem;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item {
    margin-right: 50px;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item .nav-link, .kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item a.nav-link {
    font-size: 1.2rem;
    padding: 0 0 1.5rem;
    font-weight: 500;
    color: #48465b;
    margin-bottom: 25px;
    border-bottom: 2px solid transparent;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item .nav-link:hover, .kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item a.nav-link:hover {
    border-bottom: 2px solid #591df1;
    color: #48465b;
}

.kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item .nav-link.active, .kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item a.nav-link.active {
    border-bottom: 2px solid #591df1;
    color: #591df1;
}

.kt-sc-2--wave {
    background-color: #f7f8fa !important;
    overflow: hidden;
    position: relative;
}

.kt-sc-2--wave > div {
    z-index: 1;
}

.kt-sc-2--wave:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: -1;
}

.kt-sc-2--wave:after {
    content: ' ';
    width: 1000px;
    height: 1025px;
    position: absolute;
    bottom: 65%;
    left: -250px;
    border-radius: 35%;
    background: white;
    z-index: 0;
}

.kt-sc-2--wave:after {
    bottom: 25%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (max-width: 1024px) {
    .kt-sc-2 {
        min-height: auto;
    }

    .kt-sc-2 .kt-sc__bg {
        opacity: 0.2;
    }

    .kt-sc-2 .kt-sc__top {
        min-height: auto;
    }

    .kt-sc-2 .kt-sc__top .kt-sc__content {
        padding: 4rem 0;
        margin-right: 0;
        min-height: auto;
    }

    .kt-sc-2 .kt-sc__top .kt-sc__content .kt-sc__form .input-group {
        width: 100%;
    }
}

/* Wizard */
.kt-wizard-v4 {
    flex-direction: column;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 0 0 calc(25% - 0.25rem);
    width: calc(25% - 0.25rem);
    background-color: #f7f8fb;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

@media (max-width: 1399px) {
    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
        flex: 0 0 calc(50% - 0.25rem);
        width: calc(50% - 0.25rem);
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #959cb6;
    padding: 2rem 2.5rem;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number {
    font-size: 1.3rem;
    font-weight: 600;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(44, 119, 244, 0.08);
    color: #2c77f4;
    margin-right: 1rem;
    border-radius: 0.5rem;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label {
    display: flex;
    flex-direction: column;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label .kt-wizard-v4__nav-label-title {
    font-size: 1.1rem;
    font-weight: 600;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state="current"] {
    background-color: #ffffff;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state="current"] .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number {
    color: #ffffff;
    background-color: #2c77f4;
}

.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item[data-ktwizard-state="current"] .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label .kt-wizard-v4__nav-label-title {
    color: #2c77f4;
}

@media (max-width: 768px) {
    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items {
        flex-direction: column;
        align-items: flex-start;
    }

    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
        flex: 0 0 100%;
        position: relative;
        width: 100%;
    }

    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body {
        justify-content: flex-start;
        flex: 0 0 100%;
        padding: 0.5rem 2rem;
    }
}

.kt-wizard-v4 .kt-wizard-v4__wrapper {
    display: flex;
    justify-content: center;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
    width: 60%;
    padding: 4rem 0 5rem;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eeeef4;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__form {
    margin-top: 3rem;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eeeef4;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item .kt-wizard-v4__review-title {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.7rem;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item .kt-wizard-v4__review-content {
    line-height: 1.8rem;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .kt-wizard-v4__review .kt-wizard-v4__review-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 576px) {
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions .btn {
        margin: 0 0.5rem 1rem;
    }
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
    margin-right: auto;
}

@media (max-width: 576px) {
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
        margin-right: 0.5rem;
    }
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
    margin: auto 0 auto auto;
}

@media (max-width: 576px) {
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
        margin: 0 0 1rem;
    }
}

@media (max-width: 768px) {
    .kt-wizard-v4 .kt-wizard-v4__wrapper {
        padding: 2rem;
    }

    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
        width: 100%;
        padding: 2rem 1rem 4rem;
    }
}

.kt-wizard-v4 [data-ktwizard-type="step-info"] {
    display: none;
}

.kt-wizard-v4 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
    display: block;
}

.kt-wizard-v4 [data-ktwizard-type="step-content"] {
    display: none;
}

.kt-wizard-v4 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
    display: block;
}

.kt-wizard-v4 [data-ktwizard-type="action-prev"] {
    display: none;
}

.kt-wizard-v4 [data-ktwizard-type="action-next"] {
    display: inline-block;
}

.kt-wizard-v4 [data-ktwizard-type="action-submit"] {
    display: none;
}

.kt-wizard-v4[data-ktwizard-state="first"] [data-ktwizard-type="action-prev"] {
    display: none;
}

.kt-wizard-v4[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
    display: inline-block;
}

.kt-wizard-v4[data-ktwizard-state="first"] [data-ktwizard-type="action-submit"] {
    display: none;
}

.kt-wizard-v4[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
    display: inline-block;
}

.kt-wizard-v4[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
    display: inline-block;
}

.kt-wizard-v4[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
    display: none;
}

.kt-wizard-v4[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
    display: inline-block;
}

.kt-wizard-v4[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
    display: none;
}

.kt-wizard-v4[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
    display: inline-block;
}

.kt-error-v5 {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.kt-error-v5 .kt-error_container .kt-error_title > h1 {
    font-size: 14rem;
    margin-left: 25rem;
    margin-top: 18rem;
    font-weight: 700;
    color: #314DA7;
    -webkit-text-stroke-color: white;
}

.kt-error-v5 .kt-error_container .kt-error_subtitle {
    margin-left: 26rem;
    margin-top: 3.57rem;
    font-size: 2.3rem;
    font-weight: 700;
    color: #595d6e;
}

.kt-error-v5 .kt-error_container .kt-error_description {
    margin-left: 26rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 130%;
    color: #74788d;
}

@media (min-width: 1025px) and (max-width: 1399px) {
    .kt-error-v5 {
        background-position: bottom -80px left 1300px;
    }

    .kt-error-v5 .kt-error_container .kt-error_title > h1 {
        font-weight: 700;
        font-size: 12rem;
        margin-left: 7rem;
    }

    .kt-error-v5 .kt-error_container .kt-error_subtitle {
        margin-left: 7rem;
        font-size: 2.3rem;
        font-weight: 700;
    }

    .kt-error-v5 .kt-error_container .kt-error_description {
        margin-left: 7rem;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .kt-error-v5 .kt-error_container .kt-error_title > h1 {
        font-weight: 700;
        font-size: 12rem;
        margin-left: 7rem;
    }

    .kt-error-v5 .kt-error_container .kt-error_subtitle {
        margin-left: 7rem;
        font-size: 2.3rem;
        font-weight: 700;
    }

    .kt-error-v5 .kt-error_container .kt-error_description {
        margin-left: 7rem;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%;
    }
}

@media (max-width: 768px) {
    .kt-error-v5 .kt-error_container .kt-error_title > h1 {
        font-size: 6rem;
        margin-top: 5rem;
        margin-left: 4rem;
    }

    .kt-error-v5 .kt-error_container .kt-error_subtitle {
        margin-top: 2rem;
        margin-left: 4rem;
        font-size: 2rem;
        line-height: 2rem;
    }

    .kt-error-v5 .kt-error_container .kt-error_description {
        font-size: 1.4rem;
        margin-left: 4rem;
    }
}

.kt-pricing-1 {
    overflow: hidden;
}

.kt-pricing-1.kt-pricing-1--fixed {
    width: 55%;
    margin: 0 auto;
}

.kt-pricing-1 .kt-pricing-1__items {
    background-color: transparent;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 2.14rem 0 2.14rem 0;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item {
    border-right: 0.07rem solid #ebedf2;
    padding: 0;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item:last-child {
    border-right: none;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual {
    margin-top: 10rem;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon1 {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon1:before {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    content: "";
    position: absolute;
    top: -3.93rem;
    width: 17.14rem;
    height: 0;
    border-left: 4.2rem solid transparent;
    border-right: 4.2rem solid transparent;
    border-bottom: 7.5rem solid #fff;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon1:after {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    content: "";
    position: absolute;
    top: 3.5rem;
    width: 17.14rem;
    height: 0;
    border-left: 4.2rem solid transparent;
    border-right: 4.2rem solid transparent;
    border-top: 7.5rem solid #fff;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon2 {
    left: 50%;
    margin-top: -1.43rem;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon2:before {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    content: "";
    position: absolute;
    top: -0.42rem;
    width: 8.7rem;
    height: 0;
    border-left: 2.14rem solid transparent;
    border-right: 2.14rem solid transparent;
    border-bottom: 3.71rem solid #F8F7FD;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__hexagon2:after {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    content: "";
    position: absolute;
    top: 3.3rem;
    width: 8.7rem;
    height: 0;
    border-left: 2.14rem solid transparent;
    border-right: 2.14rem solid transparent;
    border-top: 3.71rem solid #F8F7FD;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__icon {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__visual .kt-pricing-1__icon > i {
    font-size: 8.57rem;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__price {
    font-size: 3rem;
    font-weight: 700;
    display: block;
    margin-top: 20rem;
    color: #595d6e;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__price .kt-pricing-1__label {
    margin-top: 0.64rem;
    margin-left: 0.357rem;
    position: absolute;
    font-size: 1.64rem;
    font-weight: 500;
    color: #74788d;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item:nth-child(1) .kt-pricing-1__price {
    font-size: 2.14rem;
    margin-bottom: 0.571rem;
    margin-top: 220px;
    color: #595d6e;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__subtitle {
    font-size: 1.2rem;
    color: #a2a5b9;
    font-weight: 500;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__description {
    margin: 1.785rem auto;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    color: #74788d;
    font-weight: 400;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__description > span {
    display: block;
    text-align: center;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__btn {
    margin: 2.14rem 0 2.14rem 0;
}

.kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__btn > .btn {
    padding: 0.86rem 3.57rem 0.86rem 3.57rem;
}

@media (min-width: 1025px) and (max-width: 1399px) {
    .kt-pricing-1.kt-pricing-1--fixed {
        width: 100%;
    }

    .kt-pricing-1.kt-pricing-1--fixed .kt-pricing-1__items .kt-pricing-1__item {
        border-right: 1px solid #ebedf2;
    }

    .kt-pricing-1.kt-pricing-1--fixed .kt-pricing-1__items .kt-pricing-1__item:last-child {
        border-right: none;
    }
}

@media (max-width: 1024px) {
    .kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item {
        border-bottom: 1px solid #ebedf2;
        border-right: none;
    }

    .kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item:last-child {
        border-bottom: none;
    }

    .kt-pricing-1 .kt-pricing-1__items .kt-pricing-1__item .kt-pricing-1__btn {
        margin-bottom: 3.57rem;
    }
}
