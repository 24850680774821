@charset "UTF-8";
/* Fonts */
@import 'assets/css/flaticon.css';
@import 'assets/css/flaticon2.css';
@import 'assets/css/line-awesome.css';
@import "~material-design-icons/iconfont/material-icons.css";
/* Material */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
/* Theme */
@import 'assets/css/theme.css';
@import 'assets/css/sidebar.css';
@import 'assets/css/header.css';
@import 'assets/css/brand.css';
@import 'assets/css/login.css';

/* Test */
.symphony-test-page {
    background-color: #f7f8fa;
}

/* Table */
.mat-table {
    font-family: Poppins, serif;
}

.mat-table .mat-cell {
    font-size: 12px;
}

.btn-in-table {
    width: 24px !important;
    height: 24px !important;
}

.btn-in-table > i {
    font-size: 15px !important;
}

.mat-header-cell.mat-column-actions,
.mat-cell.mat-column-actions,
.mat-header-cell.mat-column-actionsEmpty,
.mat-cell.mat-column-actionsEmpty {
    flex: 0 0 100px;
}

/* Tooltip */
body[app-root] .mat-tooltip {
    background-color: #2c2e3e;
    color: #f6f6f6;
    font-family: Poppins, serif;
    font-size: 12px;
}

/* Dialog */
.mat-dialog-actions > :not(:last-child) {
    margin-right: 15px;
}

/* Tab */
.mat-tab-header {
    display: none !important;
}

.mat-header-cell.mat-column-toggle,
.mat-cell.mat-column-toggle,
.mat-header-cell.mat-column-toggleEmpty,
.mat-cell.mat-column-toggleEmpty {
    flex: 0 0 60px;
}

/* Checkbox */
.mat-checkbox-layout .mat-checkbox-label {
    font-size: 13px;
    font-weight: 300;
    font-family: Poppins, serif;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #5d78ff !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: #5d78ff !important;
}

.kt-details-text {
    color: #595d6e !important;
    font-family: Poppins, serif;
    font-size: 15px;
}

.mat-tab-group {
    font-family: Poppins, serif;
}

/* Hover */
body[app-root] .hover-cursor:hover {
    cursor: pointer;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
